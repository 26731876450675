<template>
 <div>
   <div id="page-content">
      <div class="row mt-2">
         <div class="col-lg-12">
            <div class="widjet">
               <div >
                   <div
                           class="dispflex mt-3 pt-3"
                           style="border-top: 1px solid #e5e5e5"
                           >
                           <div class="dk_icon">
                              <img
                        alt=""
                        class="avatar"
                        src="../../assets/img/img_avatar.png"
                        />
                     <span>
                       STUDENT PROFILE
                     </span>
                           </div>
                           <div class="text-right dk_iconsml">
                              <button type="button" @click="$router.go(-1)" class="btn btncl clsmdl" id="clbtn">
                                 <span>Back</span>
                              </button>
                           </div>
                        </div>
                  <!-- <div style="width: 100%">
                     <img
                        alt=""
                        class="avatar"
                        src="../../assets/img/img_avatar.png"
                        />
                     <span>
                        <h1>STUDENT PROFILE</h1>
                     </span>
                  </div> -->
               </div>
               <div class="p-3 widjetcontent">
                  <div class="tabs" style="height: 2%; background: none">
                     <input
                        type="radio"
                        name="tabs"
                        id="academictab"
                        checked="checked"
                        />
                     <label for="academictab">STUDENT PROFILE</label>
                     <div class="tab">
                        <div class="form-row">
                           <div class="form-group input-group col-md-12 pr-md-3 mt-3">
                           <div class="has-float-label " v-if="contactDetails.image && contactDetails.image.path">
                              <div class="schlogo">
                                 <img
                                 v-if="contactDetails.image && contactDetails.image.path"
                                 :src="contactDetails.image.path"
                                 
                                 alt="Avatar"
                                 class="mt-3"
                                 width="110"
                                 height="110"

                                 
                                 />
                                 <!-- @click.prevent="openSchoolImage(schoolDetails.image.path)" -->
                                 <label for="ptmbnid"
                                 >Student Photo<span class="required">*</span></label
                                 >
                                 <div class="upldIcon">
                                    <i class="fas fa-school"></i>
                                    <input
                                       
                                       type="file"
                                       ref="studentChangeImageFile"
                                       name="studentChangeImageFile"
                                       accept="image/*"
                                       @change="updateFile('studentChangeImageFile')"
                                       />

                                    <!-- <input
                                    
                                    type="file"
                                    ref="schoolImagechangeFile"
                                    name="schoolImagechangeFile"
                                    accept="image/*"
                                    @change="updateFile('schoolImage','schoolImagechangeFile')"/> -->
                                 </div>
                              </div>
                              
                           </div>
                           <div class="" v-else>
                                 <div class="schdp">
                                    <i class="fas fa-school"></i>
                                    <input
                                    class="schdpupload"
                                 type="file"
                                 ref="schoolImageFile"
                                 name="schoolImageFile"
                                 accept="image/*"
                                 @change="updateFile('schoolImageFile')"/>
                                 </div>
                              <div>
                                 Upload Student Photo
                              </div>
                           </div>
                           </div>
                           <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                              <span class="has-float-label">
                              <input
                                 type="text"
                                 disabled
                                 name="studentFirstName"
                                 v-model="contactDetails.firstName"
                                 class="form-control form-input"
                                 id="stdFirstName"
                                 placeholder="First Name"
                                 />
                              <label for="stdFirstName" 
                                 >First Name <span class="required">*</span></label
                                 >
                              </span>
                           </div>
                           <div class="form-group input-group col-md-6 mt-3">
                              <span class="has-float-label">
                              <input
                                 disabled
                                 type="text"
                                 name="studentLastName"
                                 v-model="contactDetails.lastName"
                                 class="form-control form-input"
                                 id="stdLastName"
                                 placeholder="Last Name"
                                 />
                              <label for="stdLastName" 
                                 >Last Name<span class="required">*</span></label
                                 >
                              </span>
                           </div>
                           <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                              <span class="has-float-label">
                              <input
                                 type="text"
                                 name="stdEmail"
                                 v-model="contactDetails.email"
                                 class="form-control form-input"
                                 id="stdEmail"
                                 disabled
                                 placeholder="Secondary Email"
                                 />
                              <label for="stdEmail"
                                 >Secondary Email</label
                                 >
                              </span>
                           </div>
                           <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                              <span class="has-float-label">
                              <input
                                 name="status"
                                 disabled
                                 class="form-control form-input"
                                 id="enrollmentStatus"
                                 v-model="contactDetails.admissionStatus"
                                 />
                              <label for="enrollmentStatus" 
                                 >Enrollment Status <span class="required">*</span></label
                                 >
                              </span>
                           </div>
                           <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                              <!-- max="9999-12-31" -->
                              <span class="has-float-label">
                              <input
                                 disabled
                                 type="date"
                                 onkeydown="return false"
                                 name="DOB"
                                 v-model="contactDetails.dob"
                                 class="form-control form-input"
                                 id="stdDob"
                                 placeholder="Date of Birth"
                                 />
                              <label for="stdDob" 
                                 >Date of Birth <span class="required">*</span></label
                                 >
                              </span>
                           </div>
                           <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                              <span class="has-float-label">
                              <input
                                 disabled
                                 name="Blood Group"
                                 class="form-control form-input"
                                 id="bloodGroup"
                                 v-model="contactDetails.bloodGroup"
                                 />
                              <label for="bloodGroup" 
                                 >Blood Group <span class="required">*</span></label
                                 >
                              </span>
                           </div>
                           <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                              <div style="border-bottom: 1px solid #e5e5e5; width: 100%">
                                 <label
                                    style="color: #6c757d; margin-bottom: 0.2rem"
                                    class="mr-3"
                                    >Siblings?</label
                                    >
                                 <label class="switch" style="margin-bottom: 0rem">
                                    <input
                                       disabled
                                       type="checkbox"
                                       id="sibtogBtn"
                                       v-model="contactDetails.siblings"
                                       />
                                    <div class="slider"></div>
                                 </label>
                              </div>
                           </div>
                           <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                              <span class="has-float-label">
                              <input
                                 disabled
                                 name="gender"
                                 class="form-control form-input"
                                 id="gender"
                                 v-model="contactDetails.gender"
                                 />
                              <label for="firstName" 
                                 >Gender <span class="required">*</span></label
                                 >
                              </span>
                           </div>
                           <fieldset class="accordion-group-container">
                              <div >
                                 <label
                                    style="margin: 0"
                                    for="accordiongroup-1"
                                    class="blutitle accordion-label"
                                    >
                                 Parent Details
                                 </label>
                                 <div class="form-row">
                                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                                       <span class="has-float-label">
                                       <input
                                          disabled
                                          name="pointOfContact"
                                          class="form-control form-input"
                                          id="pointOfContact"
                                          v-model="contactDetails.pointOfContact"
                                          />
                                       <label for="firstName" 
                                          >Point of contact <span class="required">*</span></label
                                          >
                                       </span>
                                    </div>
                                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                                       <span class="has-float-label" v-if="contactDetails.pointOfContact == 'Father'">
                                       <input
                                          type="text"
                                          name="fthrname"
                                          disabled
                                          v-model="contactDetails.parent.firstName"
                                          class="form-control form-input"
                                          id="fthrnameid"
                                          placeholder="Father Name"
                                          />
                                       <label for="fthrnameid" 
                                          >Father Name <span class="required">*</span></label
                                          >
                                       </span>
                                       <span class="has-float-label" v-if="contactDetails.pointOfContact == 'Mother'">
                                       <input
                                          type="text"
                                          name="Mother"
                                          disabled
                                          v-model="contactDetails.parent.firstName"
                                          class="form-control form-input"
                                          id="Mother"
                                          placeholder="Mother Name"
                                          />
                                       <label for="fthrnameid"
                                          >Mother Name <span class="required">*</span></label
                                          >
                                       </span>
                                       <span class="has-float-label" v-if="contactDetails.pointOfContact == 'Guardian'">
                                       <input
                                          type="text"
                                          name="Guardian"
                                          v-model="contactDetails.parent.firstName"
                                          class="form-control form-input"
                                          id="Guardian"
                                          disabled
                                          placeholder="Guardian Name"
                                          />
                                       <label for="Guardian" 
                                          >Guardian Name <span class="required">*</span></label
                                          >
                                       </span>
                                    </div>
                                    <div class="form-group input-group col-md-6 mt-3">
                                       <span class="has-float-label">
                                       <input
                                          type="text"
                                          name="occ"
                                          disabled
                                          v-model="contactDetails.parent.occupation"
                                          class="form-control form-input"
                                          id="occid"
                                          placeholder="Occupation"
                                          />
                                       <label for="occid"
                                          >Occupation </label
                                          >
                                       </span>
                                    </div>
                                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                                       <span class="has-float-label">
                                       <input
                                          type="text"
                                          name="Religion"
                                          disabled
                                          v-model="contactDetails.parent.religion"
                                          class="form-control form-input"
                                          id="relid"
                                          placeholder="Religion"
                                          />
                                       <label for="relid"
                                          >Religion </label
                                          >
                                       </span>
                                    </div>
                                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                                       <span class="has-float-label">
                                       <input
                                          type="text"
                                          name="nationality"
                                          disabled
                                          v-model="contactDetails.parent.nationality"
                                          class="form-control form-input"
                                          id="natid"
                                          placeholder="Nationality"
                                          />
                                       <label for="natid"
                                          >Nationality </label
                                          >
                                       </span>
                                    </div>
                                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                                       <span class="has-float-label">
                                       <input
                                          type="email"
                                          disabled
                                          name="ptemail"
                                          v-model="contactDetails.parent.email"
                                          class="form-control form-input"
                                          id="ptemailid"
                                          placeholder="Email"
                                          />
                                       <label for="ptemailid" 
                                          >Primary Email <span class="required">*</span></label
                                          >
                                       </span>
                                    </div>
                                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                                       <span class="has-float-label">
                                       <input
                                          maxlength="10"
                                          name="ptph"
                                          disabled
                                          v-model="contactDetails.parent.phoneNo"
                                          class="form-control form-input"
                                          id="ptphid"
                                          placeholder="Phone"
                                          />
                                       <label  for="ptphid">Phone <span class="required">*</span></label>
                                       </span>
                                    </div>
                                    <div class="form-group input-group col-md-6 mt-3">
                                       <span class="has-float-label">
                                       <input
                                          type="text"
                                          name="stadr"
                                          disabled
                                          v-model="contactDetails.parent.address"
                                          class="form-control form-input"
                                          id="stadrid"
                                          placeholder="Address Details"
                                          />
                                       <label for="stadrid"
                                          >Address Details</label
                                          >
                                       </span>
                                    </div>
                                 </div>
                              </div>
                              <div >
                                 <label
                                    style="margin: 0"
                                    for="accordiongroup-2"
                                    class="blutitle accordion-label"
                                    >
                                 Candidate Enrollment Details
                                 </label>
                                 <div class="form-row">
                                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                                       <span class="has-float-label">
                                       <input
                                          disabled
                                          type="text"
                                          name="roll No allocated"
                                          v-model="contactDetails.rollNoAllocated"
                                          class="form-control form-input"
                                          id="rnaltid"
                                          placeholder="Roll No. allocated"
                                          />
                                       <label for="rnaltid" 
                                          >Roll No. allocated
                                       <span class="required">*</span></label
                                          >
                                       </span>
                                    </div>
                                    <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                                       <span class="has-float-label">
                                       <input
                                          disabled
                                          name="AcademicYear"
                                          class="form-control form-input"
                                          id="deptlistid"
                                          v-model="contactDetails.academicYear"
                                          />
                                       <label for="acyearid" 
                                          >Academic Year <span class="required">*</span></label
                                          >
                                       </span>
                                    </div>
                                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                                       <span class="has-float-label">
                                       <input
                                          name="Class"
                                          class="form-control form-input"
                                          id="deptlistid"
                                          disabled
                                          v-model="contactDetails.class"
                                          />
                                       <label for="clname" 
                                          >Class Name<span class="required">*</span></label
                                          >
                                       </span>
                                    </div>
                                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                                       <span class="has-float-label">
                                       <input
                                          name="Section"
                                          class="form-control form-input"
                                          id="deptlistid"
                                          disabled
                                          v-model="contactDetails.classSection"
                                          />
                                       <label for="clname" 
                                          >Section<span class="required">*</span></label
                                          >
                                       </span>
                                    </div>
                                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                                       <!-- max="9999-12-31" -->
                                       <span class="has-float-label">
                                       <input
                                          type="date"
                                          disabled
                                          onkeydown="return false"
                                          name="DOJ"
                                          v-model="contactDetails.doj"
                                          class="form-control form-input"
                                          id="DOJ"
                                          placeholder="Date of Joining"
                                          />
                                       <label for="DOJ" 
                                          >Date of Joining <span class="required">*</span></label
                                          >
                                       </span>
                                    </div>
                                    <div class="form-group input-group col-md-6 pr-md-3 mt-3" >
                                       <!-- max="9999-12-31" -->
                                       <span class="has-float-label">
                                       <input
                                          type="date"
                                          disabled
                                          onkeydown="return false"
                                          name="Leaving"
                                          v-model="contactDetails.dateOfLeaving"
                                          class="form-control form-input"
                                          id="Leaving"
                                          placeholder="Date of Leaving"
                                          />
                                       <label for="DOJ" 
                                          >Date of Leaving <span class="required" v-if="contactDetails.admissionStatus == 'Inactive'">*</span></label
                                          >
                                       </span>
                                    </div>
                                 </div>
                              </div>
                              <div >
                                 <label v-if="contactDetails.siblings"
                                    style="margin: 0"
                                    for="accordiongroup-3"
                                    class="blutitle accordion-label"
                                    >
                                 Siblings Details
                                 </label>
                                 <div v-if="contactDetails.siblings" class="form-row">
                                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                                       <span class="has-float-label">
                                       <input
                                          disabled
                                          name="sblgname"
                                          v-model="contactDetails.siblingName"
                                          class="form-control form-input"
                                          id="sblgname"
                                          placeholder="Student Name"
                                          />
                                       <label  for="sblgname"
                                          >Sibling Name </label
                                          >
                                       </span>
                                    </div>
                                    <div class="form-group input-group col-md-6 mt-3">
                                       <span class="has-float-label">
                                       <input
                                          type="text"
                                          name="rollno"
                                          disabled
                                          v-model="contactDetails.siblingRollNo"
                                          class="form-control form-input"
                                          id="rollno"
                                          placeholder="Roll No."
                                          />
                                       <label for="rollno"
                                          >Roll No</label
                                          >
                                       </span>
                                    </div>
                                    <div class="form-group input-group col-md-6 mt-3">
                                       <span class="has-float-label">
                                       <input
                                          type="number"
                                          name="discount"
                                          v-model="contactDetails.siblingDiscount"
                                          class="form-control form-input"
                                          id="rollsssno"
                                          disabled
                                          placeholder="Sibling Discount"
                                          />
                                       <label for="rollsssno"
                                          >Sibling Discount(%)</label
                                          >
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </fieldset>
                        </div>
                      
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</template>
<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import order from "../Common/order";
import "vue-select/dist/vue-select.css";
import Select from "vue-select";

export default {
  name: "SUBSCRIPTION",

  data() {
    return {
      contactDetails: {
        firstName: "",
        lastName: "",
        admissionType: "online",
        dob: null,
        doj: null,
        dateOfLeaving: null,
        pointOfContact: 'Father',
        academicYear: null,
        gender: "Male",
        parentId: null,
        accountId: null,
        email: "",
        mobileOne: 0,
        mobileTwo: 0,
        image: "",
        addressOne: "",
        addressTwo: "",
        state: "",
        district: "",
        postalCode: "",
        country: "",
        classApplied: "",
        bloodGroup: null,
        admissionStatus: 'Active',
        siblings: false,
        rollNoAllocated: "",
        class: null,
        classSection: null,
        siblingName: null,
        siblingRollNo: "",
        siblingDiscount: ''
      },
       userDetails: {
        firstName: "",
        lastName: "",
        fatherName: "",
        motherName: "",
        email: "",
        phoneNo: null,
        password: "",
        role: "",
        membership: "Free",
        userId: "",
        gender: "",
        occupation: "",
        religion: "",
        nationality: "",
        address: "",
        registeredAs: "",
        userDevices: [],
        profile: null,
      },
     paymentDetails: {
       type: null,
       chequeNo: '',
       demandDraft: '',
       amount: null,
       terms: []
     },
     version: '',
     planDetails: {},
     studentBranchId: null,
     studentSibilingDetails: {},
     payMenttypes: [ 'Cash','Cheque','Demand Draft'],
     totalSumAmount: 0,
     adminOverrideDiscount : 0,
     grandTotalAmount: 0,
     discountAmount: 0,
     studentBillingSummary: {},
     discountAllow: false,
     selectAcademicYear: null,
     academicYearList: [],
     studentFeePlanList: [],
     planId: null,
     adhocFeePlanId: null,
     studentAdhocFeesList: [],
     studentAcademicyear: null
    };
  },
  created() {
    this.getStudentSummary();
  },

  methods: {
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    
    showDateFormat(value){
      if (value) {
        const dtf = new Intl.DateTimeFormat('en', { year: '2-digit', month: 'short', day: '2-digit' })
        const [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(new Date(value));

        return `${da}-${mo}-${ye}`;
    } else {
        return '';
    }
    },
    
    async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(`/academicYear/getAcademicYearListForSchool?accountId=${this.contactDetails.accountId}&studentBranchId=${this.studentBranchId}`,userData.token);
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async updateFile(docType) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
       
        const docFileDetails = this.$refs[docType].files[0]
      
        const fd = new FormData();
        fd.append(
          "doc",
          docFileDetails,
          docFileDetails.name,
          docFileDetails.actType
        );
        const response = await ViService.viPutOnly(
          `/document/uploadFile/parentPortal/student/photo/${this.$route.params.id}/OnlineAdmission`,
          fd,
          userData.token
        );
        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.getStudentSummary();
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getStudentSummary() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(`/contact/getStudentFreeSchoolSummary?studentId=${this.$route.params.id}`,userData.token);
        if (response.isSuccess) {
          const studentDetails = secureUI.secureGet(response.data);
          
            this.contactDetails = studentDetails
            this.studentBranchId = studentDetails.branchId

            if(this.contactDetails.dob){
                this.contactDetails.dob = this.contactDetails.dob.substring(0,10);
            }
            if(this.contactDetails.doj){
                this.contactDetails.doj = this.contactDetails.doj.substring(0,10);
            }
            if(this.contactDetails.dateOfLeaving){
                this.contactDetails.dateOfLeaving = this.contactDetails.dateOfLeaving.substring(0,10);
            }

             this.selectAcademicYear = studentDetails.academicYear._id
             this.studentAcademicyear = studentDetails.academicYear._id

            this.contactDetails.class = this.contactDetails.class.className
            this.contactDetails.classSection = this.contactDetails.classSection.name
            this.contactDetails.academicYear = this.contactDetails.academicYear.from + " - " + this.contactDetails.academicYear.to

            this.userDetails = studentDetails.parent
            this.getAcademicYearList()
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    }
    
  },
  components: {
    "v-select": Select,
  },
 
};
</script>