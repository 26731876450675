<template>
    <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
      <div id="page-content" v-if="!viLoader" style="margin-right: 12px;
    margin-left: 15px;">
        <!-- <div class="dispflex">
          <h1 class="hdrtitle" style="margin-top: 0px;">School Hub</h1>
          <button type="button" class="backbtn" @click="previousRoute">
                 <i class="fa fa-arrow-left"></i>
                </button>
        </div> -->
        <div class="webNew-dispflex"   >
       
       <!-- <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div> -->
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Inbox
       </h4>
      </div>
        <div class="row mt-2">
          <div class="col-lg-12">
            <div class="widjet">
             
              <div class="p-3 widjetcontent">
                <div class="tabs" style="height: 2%;background:none">
                 
                  <input
                    type="radio"
                    name="tabs"
                    @click="setActiveTab(1)"
                    id="classesSection"
                    :checked="currentTab == 1 ? 'checked' : ''"
                  />
                  <label for="classesSection" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Announcements </label>
                  <div class="tab">
                    <div >
                     <announcementComp/>
                    </div>
                  </div>
                  <input
                    type="radio"
                    name="tabs"
                    :checked="currentTab == 2 ? 'checked' : ''"
                    @click="setActiveTab(2)"
                    id="assignedClass"
                  />
                  <label for="assignedClass" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;" >Messages </label>
                  <div class="tab">
                      <messageComp/>
                  </div>
                  <input
                    type="radio"
                    name="tabs"
                    :checked="currentTab == 3 ? 'checked' : ''"
                    @click="setActiveTab(3)"
                    id="visitor"
                  />
                  <label for="visitor" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Visitor Tracking </label>
                  <div class="tab">
                      <visitorComp/>
                  </div>
                 
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
      <div>
      
      </div>
  
      
    </div>
  </template>
  <script>
  import ViSpinner from "../Common/ViSpinner";
  import ViService from "@/services/ViService";
  import secureUI from "../../utils/secureUI";
  import "vue-select/dist/vue-select.css";
  import Select from "vue-select";
  
  export default {
    name: "candidates",
    data() {
      return {
        viLoader: false,
        termList: [],
        maxDate: null,
        minDate: null,
        editTermList: [],
        gradeList: [],
        currentTab: 1,
        isEdit: true
      };
    },
    created() {
   /// this.getTerm()
    },
    computed: {
      styles() {
        var brwHeight = window.innerHeight;
        return {
          height: brwHeight - 90 + "px",
        };
      },
    },
   
    methods: {
      setActiveTab(index){
        this.currentTab = index
      },
     
      previousRoute() {
        let userData = secureUI.sessionGet("user");
  
       
          let data = localStorage.getItem("previousTab");
          localStorage.setItem("activeTab", data);
          localStorage.setItem("previousTab", this.$route.path);
          this.$router.push({ path: data });
       // }
      },
     
    },
    components: {
      "v-select": Select,
      ViSpinner,
      "visitorComp": () => import("./Visitor.vue"),
      "messageComp": () => import("./ViMessage.vue"),
      "announcementComp": () => import("./ViAnnouncements.vue"),
      
  
    },
  };
  </script>
  