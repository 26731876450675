<template>
  <div v-bind:style="styles">
    <div id="page-content" v-if="!viLoader">
      <div class="webNew-dispflex"   >
       
       <!-- <div class="backbox" style="margin-left: 3px;">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div> -->
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        STUDENT PROFILE
       </h4>
      </div>
      <div class="row mt-2 ml-2 mr-2">
        <div class="col-lg-12">
          <div class="widjet">
            <div>
              <div
                class="dispflex pt-3"
                
              >
                <div class="dk_icon">
                 
                </div>
                <div class="text-right mr-1">
                  <button
                      class="btn round orange tooltipt"
                      v-if="!isEdit"
                      @click.prevent="editDetails"
                    >
                      <i class="fas fa-edit"></i
                      ><span class="tooltiptext">Edit</span>
                    </button>
                    <button v-if="isEdit" type="button" @click="updateStudentDetails" class="btn btnsml" id="svbtn">
                                <span>Save</span>
                            </button>
                            <button v-if="isEdit"
                              type="button"
                              class="btn btncl"
                              id="clbtn"
                              @click.prevent="editCancel"
                            >
                                
                              <span>Cancel</span>
                            </button>

                    <!-- <button style="width: auto;height: 33px;line-height: 0px;"
                            v-if="!isEdit"
                            type="button"
                            class="btn btnsml edbtn"
                            @click.prevent="editDetails"
                          >
                             <span>Edit</span>
                          </button> -->
                 
                </div>
              </div>
            
            </div>
            <div class="p-3 widjetcontent">
              <form data-vv-scope="studentvalidate" id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    disabled
                    v-validate="'required'"
                    name="studentFirstName"
                    v-model="contactDetails.firstName"
                    class="form-control form-input"
                    id="stdFirstName"
                    placeholder="First Name"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentFirstName')"
                    class="vi-error"
                    style="top:-1em"
                    >First name is required</label
                  >
                  <label for="stdFirstName" v-else
                    >First Name <span class="required">*</span></label
                  >
                </span>
              </div>
               <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    disabled
                    name="studentFirstName"
                    v-model="contactDetails.middleName"
                    class="form-control form-input"
                    id="stdFirstName"
                    placeholder="Middle Name"
                  />
                 
                  <label for="stdFirstName"
                    >Middle Name </label
                  >
                </span>
              </div>
               <div class="form-group input-group col-md-4 mt-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="text"
                    disabled
                    name="studentLastName"
                    v-model="contactDetails.lastName"
                    class="form-control form-input"
                    id="stdLastName"
                    placeholder="Last Name"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentLastName')"
                    class="vi-error"
                    style="top:-1em"
                    >Last name is required</label
                  >
                  <label for="stdLastName" v-else
                    >Last Name<span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <input
                          disabled
                          v-validate="'required'"
                          type="text"
                          name="roll No allocated"
                          v-model="contactDetails.rollNoAllocated"
                          class="form-control form-input"
                          id="rnaltid"
                          placeholder="Roll No. allocated"
                        />

                         <label
                            v-if="errors.first('studentvalidate.roll No allocated') || !isRollNoAvailable"
                            class="vi-error"
                            style="top:-1em"
                             > {{ errors.first('studentvalidate.roll No allocated') ? 'Roll no allocated is required' : 'Roll No already exists. Please use different one.' }} </label
                          >

                        <label for="rnaltid" v-else
                          >Roll No. allocated 
                          <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label" style="margin-right: 0%;">
                          <input
                            v-validate="'required'"
                            disabled
                            name="urname"
                            v-model="userDetails.userName"
                            class="form-control form-input"
                            id="ptemailid"
                            placeholder="User Name"
                            
                          />                
                          <label
                            v-if="errors.first('studentvalidate.urname') || !isEmailIdAvailable"
                            class="vi-error"
                            style="top:-1em"
                             > {{ errors.first('studentvalidate.urname') ? 'UserName is required' : 'UserName already exists in this or another Vidhyaan School Account. It must be unique across all Accounts.' }} </label
                          >
                      
                          <label for="ptemailid" v-else
                            >User Name <span class="required">*</span></label
                          >
                        </span> 
                        <!-- <span> {{ usernameConcat }}  </span> -->
                      </div>

                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            v-validate="'required'"
                            type="email"
                            
                            :disabled="userDetails.isPasswordVerified"
                            name="ptemail"
                            v-model="userDetails.email"
                            class="form-control form-input"
                            id="ptemailid"
                            placeholder="Email"
                            
                          />
                           <a v-if="userDetails.isPasswordVerified" href="javascript:void(0);" style="color: red;" @click="resetMailId">Change Primary Email
                         </a>
                         
                        
                          <label
                            v-if="errors.first('studentvalidate.ptemail')"
                            class="vi-error"
                            style="top:-1em"
                             > Primary Email Id is required </label
                          >
                      
                          <label for="ptemailid" v-else
                            >Primary Email <span class="required">*</span></label
                          >
                        </span> 
                      </div>
                      <div class="form-group input-group col-md-4 mt-3 pr-md-3" >
                        <span class="has-float-label" style="box-shadow: 0 1px 0 0 #e5e5e5;">
                         {{ userDetails.isPasswordVerified ? 'Verified' : 'Not Verified' }}
                          <label for="stadrid" 
                            >Parent mail verification status</label
                          >
                        </span>
                      </div>

             
              <!-- <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                <span class="has-float-label">
                  <select
                    name="status"
                    disabled
                    class="form-control form-input"
                    id="enrollmentStatus"
                    v-model="contactDetails.admissionStatus"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in enrollmentStatusList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                   
                 
                  <label for="enrollmentStatus" 
                    >Enrollment Status <span class="required">*</span></label
                  >
                </span>
              </div> -->
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <!-- max="9999-12-31" -->
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="date"
                    :max="minDob"
                    onkeydown="return false"
                    name="DOB"
                    disabled
                    v-model="contactDetails.dob"
                    class="form-control form-input"
                    id="stdDob"
                    placeholder="Date of Birth"
                  />
                  <label
                    v-if="errors.first('studentvalidate.DOB')"
                    class="vi-error"
                    style="top:-1em"
                    >Date of birth is required</label
                  >
                 
                 
                  <label for="stdDob" v-else
                    >Date of Birth <span class="required">*</span></label
                  >
                </span>
              </div>
               <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <input
                          v-validate="'required'"
                          type="date"
                          :min="contactDetails.dob"
                          disabled
                          onkeydown="return false"
                          name="DOJ"
                          v-model="contactDetails.doj"
                          class="form-control form-input"
                          id="DOJ"
                          placeholder="Date of Joining"
                        />
                        <label
                          v-if="errors.first('studentvalidate.DOJ')"
                          class="vi-error"
                          style="top:-1em"
                          >Date of Joining is required</label
                        >
                      
                      
                        <label for="DOJ" v-else
                          >Date of Joining <span class="required">*</span></label
                        >
                      </span>
                    </div>
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3" v-if="contactDetails.dateOfLeaving">
                      <!-- max="9999-12-31" -->
                      <span class="has-float-label">
                        <input
                        
                         v-validate="contactDetails.admissionStatus == 'Inactive' ? 'required' : false"
                          type="date"
                          disabled
                          :min="contactDetails.doj"
                          onkeydown="return false"
                          name="Leaving"
                          v-model="contactDetails.dateOfLeaving"
                          class="form-control form-input"
                          id="Leaving"
                          placeholder="Date of Leaving"
                        />
                        <label
                          v-if="errors.first('studentvalidate.Leaving')"
                          class="vi-error"
                          style="top:-1em"
                          >Date of Leaving is required</label
                        >
                        <label for="DOJ" v-else
                          >Date of Leaving <span class="required" v-if="contactDetails.admissionStatus == 'Inactive'">*</span></label
                        >
                      </span>
                    </div>
             
              
             
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <div style="border-bottom: 1px solid #e5e5e5; width: 100%">
                  <label
                    style="color: #6c757d; margin-bottom: 0.2rem"
                    class="mr-3"
                    >Siblings?</label
                  >
                  <label class="switch" style="margin-bottom: 0rem">
                    <input
                      type="checkbox"
                      id="sibtogBtn"
                      disabled
                      v-model="contactDetails.siblings"
                    />
                    <div class="slider"></div>
                  </label>
                </div>
              </div>
 
              <fieldset class="accordion-group-container">
                  <div >
                 
                  <label v-if="contactDetails.siblings"
                    style="margin: 0"
                    for="accordiongroup-3"
                    class="blutitle accordion-label"
                  >
                    SIBLINGS DETAILS
                  </label>
                  <div v-if="contactDetails.siblings" class="form-row">
                    <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <select
                          v-validate="'required'"
                          name="sblgname"
                          disabled
                          v-model="contactDetails.siblingName"
                          class="form-control form-input"
                          id="sblgname"
                          @change="setSblingRollNo(contactDetails.siblingName)"
                          placeholder="Student Name"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in auvaiableStudentList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.firstName }}  
                          </option>
                        </select>
                        <label
                            v-if="errors.first('studentvalidate.sblgname')"
                            class="vi-error"
                            style="top:-1em"
                            >Sibling Name is required</label
                          >
                        <label v-else for="sblgname"
                          >Sibling Name </label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3">
                      <span class="has-float-label">
                        <input
                          type="text"
                          name="rollno"
                          disabled
                          v-model="contactDetails.siblingRollNo"
                          class="form-control form-input"
                          id="rollno"
                        />
                        <label for="rollno"
                          >Roll No</label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3">
                      <span class="has-float-label">
                        <input
                          type="text"
                          name="rollno"
                          disabled
                          v-model="contactDetails.siblingUserId"
                          class="form-control form-input"
                          id="rollno"
                        />
                        <label for="rollno"
                          >UserId</label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3">
                      <span class="has-float-label">
                        <input
                          type="number"
                          name="discount"
                          disabled
                          v-model="contactDetails.siblingDiscount"
                          class="form-control form-input"
                          id="rollsssno"
                          v-validate="'numeric|min:0|max_value:100'"
                        />
                        <label
                            v-if="errors.first('studentvalidate.discount')"
                            class="vi-error"
                            style="top:-1em"
                            >Sibling Discount (max 100%)</label
                          >
                        <label v-else for="rollsssno"
                          >Sibling Discount(%)</label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3">
                      <span class="has-float-label">
                        <input
                         disabled
                          name="discount"
                          v-model="contactDetails.siblingClass"
                          class="form-control form-input"
                          id="rollsssno"
                        />
                        
                        <label for="rollsssno"
                          >Class</label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3">
                      <span class="has-float-label">
                        <input
                          disabled
                          name="discount"
                          v-model="contactDetails.siblingSection"
                          class="form-control form-input"
                          id="rollsssno"
                        />
                       
                        <label for="rollsssno"
                          >Section</label
                        >
                      </span>
                    </div>
                    
                  </div>
                </div>
               

                <div>
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-2"
                    class="blutitle accordion-label"
                  >
                    CLASS SECTION DETAILS
                  </label>
                  <div class="form-row">
                   
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                         <input
                            type="text"
                            v-validate="'required'"
                          name="AcademicYear"
                          disabled
                          class="form-control form-input"
                          id="deptlistid"
                          v-model="contactDetails.academicYear"
                          />

                        <label for="acyearid" 
                          >Academic year <span class="required">*</span></label
                        >
                      </span>
                    </div>

                    <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <input
                            type="text"
                            v-validate="'required'"
                          name="Class"
                          class="form-control form-input"
                          id="deptlistid"
                          disabled
                          v-model="contactDetails.class"
                          />

                        
                        <label for="clname" 
                          >Class Name<span class="required">*</span></label
                        >
                      </span>
                    </div>
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <input
                            type="text"
                            v-validate="'required'"
                          name="Section"
                          class="form-control form-input"
                          id="deptlistid"
                          disabled
                          v-model="contactDetails.classSection"
                          />
                        
                        <label for="clname" 
                          >Section<span class="required">*</span></label
                        >
                      </span>
                    </div>
                     
                  </div>
                </div>

                 <div >
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-1"
                    class="blutitle accordion-label"
                  >
                    PARENT DETAILS
                  </label>
                    <div class="form-row">
                       
                       <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="pointOfContact"
                            :disabled="!isEdit"
                            class="form-control form-input"
                            id="pointOfContact"
                            v-model="contactDetails.pointOfContact"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in pointOfList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidate.pointOfContact')"
                            class="vi-error"
                            style="top:-1em"
                            >Point of contact is required</label
                          > 
                          <label for="firstName" v-else
                            >Point of contact <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                     </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            :disabled="!isEdit"
                            name="fthrname"
                            v-validate="contactDetails.pointOfContact == 'Father' ? 'required' : false"
                            v-model="contactDetails.fatherFirstName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Father First Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.fthrname')"
                            class="vi-error"
                            style="top:-1em"
                            >Father First Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Father First Name <span v-if="contactDetails.pointOfContact == 'Father'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            :disabled="!isEdit"
                            name="fthrlastname"
                            v-validate="contactDetails.pointOfContact == 'Father' ? 'required' : false"
                            v-model="contactDetails.fatherLastName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Father Last Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.fthrlastname')"
                            class="vi-error"
                            style="top:-1em"
                            >Father Last Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Father Last Name <span v-if="contactDetails.pointOfContact == 'Father'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                     
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="number"
                            :disabled="!isEdit"
                            onKeyPress="if(this.value.length==10) return false;" 
                            v-validate="contactDetails.pointOfContact == 'Father' ? 'required|numeric|min:10|max:10' : 'numeric|min:10|max:10'"
                            name="fphno"
                            v-model="contactDetails.mobileOne"
                            class="form-control form-input"
                            id="ptphid"
                            placeholder="Mobile"
                          />
                         
                          <label
                            v-if="errors.first('studentvalidate.fphno') || !isMobileAvailable"
                            class="vi-error"
                            style="top:-1em"
                            > {{ errors.first('studentvalidate.fphno') ? 'Please enter a valid mobile number(10 digits)' : 'Mobile Number already exists in this or another Vidhyaan School Account. It must be unique across all Accounts.' }}</label
                          >
                          <label v-else for="ptphid">Mobile <span v-if="contactDetails.pointOfContact == 'Father'" class="required">*</span></label>
                        </span>
                      </div>
                       <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            name="fthrname"
                            disabled
                            v-model="contactDetails.fatherOccuption"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Occupation"
                          />
                          
                          <label for="fthrnameid" 
                            >Occupation </label
                          >
                        </span>
                        
                      </div>
                       <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            name="mfirstName"
                            :disabled="!isEdit"
                            v-validate="contactDetails.pointOfContact == 'Mother' ? 'required' : false"
                            v-model="contactDetails.motherFirstName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Father First Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.mfirstName')"
                            class="vi-error"
                            style="top:-1em"
                            >Mother First Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Mother First Name <span v-if="contactDetails.pointOfContact == 'Mother'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            name="mlastNmae"
                            :disabled="!isEdit"
                            v-validate="contactDetails.pointOfContact == 'Mother' ? 'required' : false"
                            v-model="contactDetails.motherLastName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Mother Last Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.mlastNmae')"
                            class="vi-error"
                            style="top:-1em"
                            >Mother Last Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Mother Last Name <span v-if="contactDetails.pointOfContact == 'Mother'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                      
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="number"
                            :disabled="!isEdit"
                            onKeyPress="if(this.value.length==10) return false;" 
                            v-validate="contactDetails.pointOfContact == 'Mother' ? 'required|numeric|min:10|max:10' : 'numeric|min:10|max:10'"
                            name="mphno"
                            v-model="contactDetails.mobileTwo"
                            class="form-control form-input"
                            id="ptphid"
                            placeholder="Mobile"
                          />
                         
                          <label
                            v-if="errors.first('studentvalidate.mphno') || !isMobileAvailable"
                            class="vi-error"
                            style="top:-1em"
                            > {{ errors.first('studentvalidate.mphno') ? 'Please enter a valid mobile number(10 digits)' : 'Mobile Number already exists in this or another Vidhyaan School Account. It must be unique across all Accounts.' }}</label
                          >
                          <label v-else for="ptphid">Mobile <span v-if="contactDetails.pointOfContact == 'Mother'" class="required">*</span></label>
                        </span>
                      </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            :disabled="!isEdit"
                            v-model="contactDetails.motherOccuption"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Occupation"
                          />
                          
                          <label for="fthrnameid" 
                            >Occupation</label
                          >
                        </span>
                        
                      </div>
                       <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            name="gfirstName"
                            :disabled="!isEdit"
                            v-validate="contactDetails.pointOfContact == 'Guardian' ? 'required' : false"
                            v-model="contactDetails.guardianFirstName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Father First Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.gfirstName')"
                            class="vi-error"
                            style="top:-1em"
                            >Guardian First Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Guardian First Name <span v-if="contactDetails.pointOfContact == 'Guardian'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            name="glastname"
                            v-validate="contactDetails.pointOfContact == 'Guardian' ? 'required' : false"
                            v-model="contactDetails.guardianLastName"
                            class="form-control form-input"
                            id="fthrnameid"
                            :disabled="!isEdit"
                            placeholder="Father First Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.glastname')"
                            class="vi-error"
                            style="top:-1em"
                            >Guardian Last is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Guardian Last Name <span v-if="contactDetails.pointOfContact == 'Guardian'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                     
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="number"
                            onKeyPress="if(this.value.length==10) return false;" 
                            v-validate="contactDetails.pointOfContact == 'Guardian' ? 'required|numeric|min:10|max:10' : 'numeric|min:10|max:10'"
                            name="gphno"
                            :disabled="!isEdit"
                            v-model="contactDetails.mobileThree"
                            class="form-control form-input"
                            id="ptphid"
                            placeholder="Phone"
                          />
                         
                          <label
                            v-if="errors.first('studentvalidate.gphno') || !isMobileAvailable"
                            class="vi-error"
                            style="top:-1em"
                            > {{ errors.first('studentvalidate.gphno') ? 'Please enter a valid mobile number(10 digits)' : 'Mobile Number already exists in this or another Vidhyaan School Account. It must be unique across all Accounts.' }}</label
                          >
                          <label v-else for="ptphid">Mobile <span v-if="contactDetails.pointOfContact == 'Guardian'" class="required">*</span></label>
                        </span>
                      </div>
                       <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            :disabled="!isEdit"
                            v-model="contactDetails.guardianOccuption"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Occupation"
                          />
                         
                          <label for="fthrnameid" 
                            >Occupation </label
                          >
                        </span>
                        
                      </div>
                      
              
                      
                      
                    </div>
                </div>
                  <div>
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-2"
                    class="blutitle accordion-label"
                  >
                    ADDRESS DETAILS
                  </label>
                  <div class="form-row">
                     <span style="width: 100%;" class="pl-1 my-2">
                                  <strong>Current Address</strong> &nbsp;
                                  &nbsp;

                                 
                                </span>
                    <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            :disabled="!isEdit"
                            name="occ"
                            v-model="contactDetails.addressOne"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Address Line 1"
                          />
                          <label for="occid"
                            >Address Line 1 </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            :disabled="!isEdit"
                            name="occ"
                            v-model="contactDetails.addressTwo"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Address Line 2"
                          />
                          <label for="occid"
                            >Address Line 2 </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            :disabled="!isEdit"
                            v-model="contactDetails.country"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Country"
                          />
                          <label for="occid"
                            >Country</label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <select
                            name="gendddfddder"
                            class="form-control form-input"
                            id="gedfdnder"
                            :disabled="!isEdit"
                            v-model="contactDetails.state"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in stateList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>

                          <!-- <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.state"
                            class="form-control form-input"
                            id="occid"
                            placeholder="State"
                          /> -->
                          <label for="occid"
                            >State </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.district"
                            class="form-control form-input"
                            id="occid"
                            :disabled="!isEdit"
                            placeholder="City/Town"
                          />
                          <label for="occid"
                            >City/Town</label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.postalCode"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Pin Code"
                          />
                          <label for="occid"
                            >Pin Code</label
                          >
                        </span>
                      </div>
                      <div class="form-row" style="margin-bottom: 2%;" >
                  <div
                            class="form-group input-group col-md-12 mt-3"
                            
                          >
                              <!-- Permanent address same as current address ? -->
                              
                              <span >  Permanent address same as current address ?</span>

                               <div class="nottble" style="margin-top:0px;">
                                <div class=" chkbox" data-title="Select">
                                    <label class="custcheckbox">
                                      <input  type="checkbox"  :disabled="!isEdit" @change="setPermentAddress(contactDetails.isPermentAddress)" v-model="contactDetails.isPermentAddress" />
                                      <span class="checkmarkchk"></span>
                                    </label>
                                  </div>
                                  
                                </div>

                                 
                            
                          </div>
                           </div>
                      <!-- <span style="width: 100%;" class="pl-1 my-2">
                                 

                                  <span style="color: #6c757d" class="ml-3"
                                    >Permanent address same as current address ?
                                    </span
                                  >
                                  <input
                                  :disabled="!isEdit"
                                    @change="setPermentAddress(contactDetails.isPermentAddress)"
                                    v-model="contactDetails.isPermentAddress"
                                    type="checkbox"
                                  />
                                </span>
                     
                     -->
                  </div>
                  <div class="form-row" v-if="!contactDetails.isPermentAddress">
                     <span style="width: 100%;" class="pl-1 my-2">
                                  <strong>Permanent Address</strong> &nbsp;
                                  &nbsp;

                                 
                                </span>
                    <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            :disabled="!isEdit"
                            v-model="contactDetails.permentaddressOne"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Address Line 1"
                          />
                          <label for="occid"
                            >Address Line 1 </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            :disabled="!isEdit"
                            v-model="contactDetails.permentaddressTwo"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Address Line 2"
                          />
                          <label for="occid"
                            >Address Line 2 </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            :disabled="!isEdit"
                            v-model="contactDetails.permentcountry"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Country"
                          />
                          <label for="occid"
                            >Country</label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <select
                            name="gendddfddder"
                            class="form-control form-input"
                            id="gedfdnder"
                            :disabled="!isEdit"
                            v-model="contactDetails.permentstate"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in stateList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>

                          <!-- <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.state"
                            class="form-control form-input"
                            id="occid"
                            placeholder="State"
                          /> -->
                          <label for="occid"
                            >State </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            :disabled="!isEdit"
                            v-model="contactDetails.permentdistrict"
                            class="form-control form-input"
                            id="occid"
                            placeholder="City/Town"
                          />
                          <label for="occid"
                            >City/Town</label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            :disabled="!isEdit"
                            v-model="contactDetails.permentpostalCode"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Pin Code"
                          />
                          <label for="occid"
                            >Pin Code</label
                          >
                        </span>
                      </div>
                    
                     
                    
                  </div>
                </div>
                  <div>
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-2"
                    class="blutitle accordion-label"
                  >
                    OTHER INFORMATION
                  </label>
                  <div class="form-row">
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    name="Blood Group"
                    class="form-control form-input"
                    id="bloodGroup"
                    disabled
                    v-model="contactDetails.bloodGroup"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(blood, index) in bloodGroupList"
                      :key="index"
                      :value="blood"
                    >
                      {{ blood }}
                    </option>
                  </select>
                 
                  <label for="bloodGroup"
                    >Blood Group </label
                  >
                </span>
              </div>
             

                  <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            name="gender"
                            class="form-control form-input"
                            id="gender"
                            disabled
                            v-model="contactDetails.gender"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in genderList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                         
                          <label for="firstName" 
                            >Gender </label
                          >
                        </span>
                      </div>
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                            disabled
                            v-model="userDetails.religion"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Religion"
                          />
                          <label for="relid"
                            >Religion </label
                          >
                        </span>
                        
                      </div>

                      <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            disabled
                            name="nationality"
                            v-model="userDetails.nationality"
                            class="form-control form-input"
                            id="natid"
                            placeholder="Nationality"
                          />
                          <label for="natid"
                            >Nationality </label
                          >
                        </span>
                      </div>
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                            disabled
                            v-model="contactDetails.category"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Religion"
                          />
                          <label for="relid"
                            >Category </label
                          >
                        </span>
                        
                      </div>
                      <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                            disabled
                            v-model="contactDetails.previousSchool"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Religion"
                          />
                          <label for="relid"
                            >Previous School  </label
                          >
                        </span>
                        
                      </div>
                      <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                            disabled
                            v-model="contactDetails.previousClassPassed"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Previous Class Passed"
                          />
                          <label for="relid"
                            >Previous Class Passed </label
                          >
                        </span>
                        
                      </div>
                       <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <div style="border-bottom: 1px solid #e5e5e5; width: 100%">
                  <label
                    style="color: #6c757d; margin-bottom: 0.2rem"
                    class="mr-3"
                    >RTE Quota</label
                  >
                  <label class="switch" style="margin-bottom: 0rem">
                    <input
                      type="checkbox"
                      id="sibtogBtn"
                      disabled
                      v-model="contactDetails.isrtequota"
                    />
                    <div class="slider"></div>
                  </label>
                </div>
              </div>
               <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <div style="border-bottom: 1px solid #e5e5e5; width: 100%">
                  <label
                    style="color: #6c757d; margin-bottom: 0.2rem"
                    class="mr-3"
                    >Disability</label
                  >
                  <label class="switch" style="margin-bottom: 0rem">
                    <input
                      type="checkbox"
                      disabled
                      id="sibtogBtn"
                      v-model="contactDetails.isDisability"
                    />
                    <div class="slider"></div>
                  </label>
                </div>
              </div>
                    
                  </div>
                </div>
              </fieldset>
            </div>
            <div
              class=" mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
             
              <div class="text-center dk_iconsml">
                
              
                          <div v-if="isEdit">
                            <button type="button" @click="updateStudentDetails" class="btn btnsml" id="svbtn">
                                <span>Save</span>
                            </button>
                            <button
                              type="button"
                              class="btn btncl"
                              id="clbtn"
                              @click.prevent="editCancel"
                            >
                                
                              <span>Cancel</span>
                            </button>
                          </div>
              </div>
            </div>
          </form>
            </div>
          </div>
        </div>
      </div>
    </div>
   <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
  </div>
</template>
<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import order from "../Common/order";
import "vue-select/dist/vue-select.css";
import Select from "vue-select";
import ViSpinner from "../Common/ViSpinner";

export default {
  name: "SUBSCRIPTION",

  data() {
    return {
      contactDetails: {

        middleName : "",
        fatherFirstName : "",
        fatherLastName : "",
        fatherOccuption : "",
        motherFirstName : "",
        motherLastName : "",
        motherOccuption : "",
        guardianFirstName : "",
        guardianLastName : "",
        guardianOccuption : "",
        mobileThree: null,
        isPermentAddress: false,
        isrtequota: false,
        isDisability: false,
        permentaddressOne  : "",
        permentaddressTwo : "",
        permentstate : "",
        permentdistrict : "",
        permentpostalCode : "",
        permentcountry : "",
        category : "",
        previousSchool : "",
        previousClassPassed : "",
        siblingUserId: "",
        siblingClass: "",
        siblingSection: "",
        firstName : "",
        lastName: "",
        admissionType: "online",
        dob: null,
        doj: null,
        dateOfLeaving: null,
        pointOfContact: "",
        academicYear: null,
        gender: "",
        parentId: null,
        accountId: null,
        email: "",
        mobileOne: null,
        mobileTwo: null,
        image: {},
        addressOne: "",
        addressTwo: "",
        state: "",
        district: "",
        postalCode: "",
        country: "",
        classApplied: "",
        bloodGroup: null,
        admissionStatus: 'Active',
        siblings: false,
        rollNoAllocated: "",
        class: null,
        classSection: null,
        siblingName: null,
        siblingRollNo: "",
        siblingDiscount: ''
      },

      userDetails: {
        firstName: "",
        lastName: "",
        fatherName: "",
        motherName: "",
        email: "",
        phoneNo: null,
        password: "",
        role: "",
        membership: "Free",
        userId: "",
        gender: "",
        occupation: "",
        religion: "",
        nationality: "",
        address: "",
        registeredAs: "",
        userDevices: [],
        profile: null,
        userName: ''
      },

      enrollmentStatusList: [
        "Active",
        "Inactive",
        "Submitted", 
        "In Review",
        "Assessment",
        "Approved", 
        "Reject", 
        "Enrolled"
      ],
      pointOfList: [
       'Father',
       'Mother',
       'Guardian'
      ],
      genderList: [
       'Male',
       'Female'
      ],
      usernameConcat: '',
      isVerifyCode : false,
      generatedVerifyCode: null,
      userVerifyCode: null,
      newPrimaryEmail: '',
      invalidNewMail: false,
      isNewPrimaryMailValid: true,
      searchWords: '',
      skip: 0, // set the skip info
      limit: 50, // set the limit info
      currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      countAll: true,
      totalCount: [],
      selectClass : null,
      deleteId: null,
      editId: null,
      feesNotPaid: false,
      userId: null,
      minPlanDate: null,
      maxPlanDate: null,
      classList: [],
      prevPhno: '',
      prevEmailId: '',
      prevROllNo: '',
      isMobileAvailable: true,
      isEmailIdAvailable: true,
      isRollNoAvailable: true,
      minDob: null,
      sectionList: [],
      studentList: [],
      academicYearList: [],
      allStudentList: [],
      parentMailVerifiyed: false,
      selectAcademicYear: null,
      selectedStatus: null,
      selectedSibiling: false,
      allSectionList: [],
      viLoader: false,
      statusList: [ "Active","Inactive"],
      stateList: [
         "Andaman and Nicobar Islands",
        "Andhra Pradesh",
         "Arunachal Pradesh",
         "Assam",
         "Bihar",
         "Chandigarh",
         "Chhattisgarh",
         "Dadra and Nagar Haveli",
         "Daman and Diu",
         "Delhi",
         "Goa",
         "Gujarat",
         "Haryana",
         "Himachal Pradesh",
         "Jammu and Kashmir",
         "Jharkhand",
         "Karnataka",
         "Kerala",
         "Lakshadweep",
         "Madhya Pradesh",
         "Maharashtra",
         "Manipur",
         "Meghalaya",
         "Mizoram",
         "Nagaland",
         "Odisha",
         "Puducherry",
         "Punjab",
         "Rajasthan",
         "Sikkim",
        "Tamil Nadu",
        "Telangana",
         "Tripura",
         "Uttar Pradesh",
         "Uttarakhand",
         "West Bengal",
      ],
      version: "",
      planDetails: {},
      studentSibilingDetails: {},
      payMenttypes: ["Cash", "Cheque", "Demand Draft"],
      totalSumAmount: 0,
      adminOverrideDiscount: 0,
      grandTotalAmount: 0,
      studentBranchId: null,
      convenienceFeeTotalAmount: 0,
      adhocFeeTotalAmount: null,
      discountAmount: 0,
      studentBillingSummary: {},
      discountAllow: false,
      selectAcademicYear: null,
      studentFeePlanList: [],
      planId: null,
      adhocFeePlanId: null,
      studentAdhocFeesList: [],
      studentAcademicyear: null,
      isEdit: false,
      paymentFaildObj: {},
      schoolDetails: {},
      isRazorpayVerifiyed: '',
      razorPayAccountId: '',
      razorPayTestKeyId: '',
      razorPaySecretId: '',
      razorPayTestSecretId: '',
      isPayMentMode: false,
      bloodGroupList: ["A+", "O+", "B+", "AB+", "A-", "O-", "B-", "AB-"]
    };
  },
  created() {
    this.getStudentSummary(true);
    

  },
  computed: {
    styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
    }
  },

  methods: {
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",this.$route.path);
     //this.$router.go(-1)
      this.$router.push({ path: data });
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
   
    editDetails() {
      this.isEdit = true;
    },
   
    async updateStudentDetails() {
     

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
       // this.viLoader = true;
        const response = await ViService.viXPut(
          `/contact/student/parent/update/studentDetails/${this.$route.params.id}`,
          {
            contactDetails: this.contactDetails,
            userDetails: this.userDetails
          },
          userData.token
        );
       

        if (response.isSuccess) {
          this.isEdit = false
      
          this.getStudentSummary(false)
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    
    },
    navigatetolink(value){
      window.open(value, "_blank");
    },
    editCancel() {
      this.isEdit = false;
      this.getStudentSummary(false);
    },
     resetMailId(){
      this.$bvModal.show("resetMailPopup");
    },
    checkSelected(term, value, index) {
      Object.values(this.studentBillingSummary).forEach((x, i) => {
        if (value) {
          if (i < index && x.status != "Paid") {
            x.isSelected = true;
          }
        } else {
          if (i > index && x.status != "Paid") {
            x.isSelected = false;
          }
        }
      });

      let finalAmount = 0;

      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
      if (allTermList.length > 0) {
        const selectedList = allTermList.filter(
          (x) => x.isSelected && x.status != "Paid"
        );

        if (selectedList.length > 0) {
          for (let x of selectedList) {
            finalAmount = this.totalFeesTerm(x.name) + finalAmount;
          }
          this.totalSumAmount = finalAmount;
        }else{
          this.totalSumAmount = 0
        }
        
      }

      this.getSibilingDiscountAmount();
    },
    
    async print() {
      const modal = document.getElementById("printSection").shadowRoot.innerHTML.toString().trim();;
      const cloned = modal.cloneNode(true);
      let section = document.getElementById("print");

      if (!section) {
        section = document.createElement("div");
        section.id = "print";
        document.body.appendChild(section);
      }

      section.innerHTML = "";
      section.appendChild(cloned);
      window.print();
    },
  
    showDateFormat(value) {
      if (value) {
        const dtf = new Intl.DateTimeFormat("en", {
          year: "2-digit",
          month: "short",
          day: "2-digit",
        });
        const [{ value: mo }, , { value: da }, , { value: ye }] =
          dtf.formatToParts(new Date(value));

        return `${da}-${mo}-${ye}`;
      } else {
        return "";
      }
    },
   
   
   

    
    async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          `/academicYear/getAcademicYearListForSchool?accountId=${this.contactDetails.accountId}&studentBranchId=${this.studentBranchId}`,
          userData.token
        );
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
  
    async getStudentSummary(value) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        if(value){
          this.viLoader = true
        }

        const response = await ViService.viXGet(
          `/contact/getStudentSummary?studentId=${this.$route.params.id}`,
          userData.token
        );
        if (response.isSuccess) {
          const studentDetails = secureUI.secureGet(response.data);
          
          this.contactDetails = studentDetails;
          this.studentBranchId = studentDetails.branchId

          if (this.contactDetails.dob) {
            this.contactDetails.dob = this.contactDetails.dob.substring(0, 10);
          }
          if (this.contactDetails.doj) {
            this.contactDetails.doj = this.contactDetails.doj.substring(0, 10);
          }
          if (this.contactDetails.dateOfLeaving) {
            this.contactDetails.dateOfLeaving =
              this.contactDetails.dateOfLeaving.substring(0, 10);
          }

          this.selectAcademicYear = studentDetails.academicYear._id;
          this.studentAcademicyear = studentDetails.academicYear._id;

          this.contactDetails.class = this.contactDetails.class.className;
          this.contactDetails.classSection = this.contactDetails.classSection.name;
          this.contactDetails.academicYear = this.contactDetails.academicYear.from + " - " + this.contactDetails.academicYear.to;

          this.userDetails = studentDetails.parent;
          this.getAccountDetails();
          this.getAcademicYearList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
        this.viLoader = false

      }
    },

    async getAccountDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet("/account/getAccountConfigDetails?accountId=" + this.contactDetails.accountId,userData.token);

        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);
          
          if (details.version) {
            this.version = details.version;
            this.razorPayAccountId = details.razorPayAccountId || ''
            this.isRazorpayVerifiyed = details.razorPayKeyId || '';
            this.razorPayTestKeyId = details.razorPayTestKeyId || ''
            this.razorPaySecretId = details.razorPaySecretId || ''
            this.razorPayTestSecretId = details.razorPayTestSecretId || ''
            this.schoolDetails = details
            
            this.isPayMentMode = details.isPayMentMode || false
          }
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    }
  
  },
  components: {
    "v-select": Select,
    ViSpinner
  },
};
</script>
<style scoped>
ul,
ul li,
p,
div,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

#invoice {
  margin: 0 auto;
  width: 999px;

  clear: both;
  position: relative;
  background: #fff;
}
.feedate {
  font-size: 0.78rem;
  text-align: right;
  margin: 0 auto;
  padding: 10px 0;
  width: 999px;
}
table,
td,
th {
  border: 1px solid black;
}

@media screen {
  #print {
    display: none;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #print,
  #print * {
    visibility: visible;
  }
  #print {
    position: absolute;
    left: 0;
    top: 0;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
}

/* table{
         border-collapse:collapse;
         width:100%;
        clear:both;
      } */
</style>